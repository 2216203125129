<!--
  作者：顾先生
-->
<template>
  <div class="carousel-container">
    <div class="block">
      <el-carousel height="330px">
        <el-carousel-item v-for="(item,key) in imgList" :key="key">
          <img :src="item.idView">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="right">
      <div class="right_top">
        <div class="right_top_1"><span style="font-size: 20px;line-height:30px">栏目组致力于大力宣传知名自主品牌，讲好中国品牌故事，提高自主品牌影响力和认知度，以诚信为内涵，在全国范围内遴选出有行业代表性的民营企业作为扶持对象，在全国范围内打造中国榜样和品牌新势力</span></div>
        <div class="right_top_2"><span style="color:#7E7E7E;">来源：</span><span style="color: #B32418">信用中国</span></div>
      </div>
      <div class="right_bottom">
        <div class="right_bottom_1"><span style="font-size: 20px;line-height:30px">中企信立信企业计划是针对无经营异常、无失信、无重大违法、无重大负面舆情企业，加入中企信立信企业计划并签守信承诺书（立信牌匾有唯一的二维码），通过信用平台接受社会监督，也助力企业/商户线下生产经营活动</span></div>
        <div class="right_bottom_2"><span style="color:#7E7E7E;">来源：</span><span style="color: #B32418">立信企业</span></div>
      </div>
<!--      <div class="right_bottom_font">下一页 >></div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  // 注册组件
  components: {},
  // 注册方法
  methods: {},
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      imgList: [
        { id: 0, idView: require('@/assets/images/640.jpg') },
        { id: 1, idView: require('@/assets/images/640 (1).jpg') },
        { id: 2, idView: require('@/assets/images/640 (2).jpg') },
        { id: 3, idView: require('@/assets/images/640 (3).jpg') }
      ]

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.carousel-container{
  display: flex;
  .block{
    width:350px;
    height: 330px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .right{
    margin-left: 20px;
    flex: 2;
    height: 330px;
    background-color:#f5f5f5;
    border-radius: 10px;
    // 右侧顶部排版
    .right_top{
      margin: 0 auto;
      padding-top:30px;
      width: 760px;
      height: 150px;
      border-bottom: 2px solid #E2E2E2;
      .right_top_1{
        margin: 0 auto;
        width:100%;
        height:75px;
      }
      .right_top_2{
        margin-left:600px;
        margin-top: 10px;
        width: 130px;
        height: 18px;
      }
    }
    // 右侧底部排版
    .right_bottom{
      margin: 0 auto;
      margin-top: 20px;
      width: 760px;
      height: 120px;

      .right_bottom_1{
        margin: 0 auto;
        width:100%;
        height:75px;
      }
      .right_bottom_2{
        margin-left:600px;
        margin-top: 10px;
        width: 130px;
        height: 18px;
      }
    }
    // 右侧底部文字
    .right_bottom_font{
      padding-top:5px;
      padding-bottom:5px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      font-weight:600;
      color: #B32418;
    }
  }
}
</style>
