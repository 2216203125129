import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
// 引入全局css
import './style/index.css'
// 引入Element-Ui-css
import 'element-ui/lib/theme-chalk/index.css'
// 引入Element-Ui
import ElementUI from 'element-ui'
// 在Vue上挂在Element-Ui
Vue.use(ElementUI)
Vue.use(Vant)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
