<!--
  作者：顾先生
-->
<template>
  <div class="HuaDong_container">
    <Vcode :show="isShow" @success="success" @close="close"></Vcode>
    <button @click="submit">登录</button>
  </div>
</template>

<script>
import Vcode from 'vue-puzzle-vcode'
export default {
  name: 'HuaDong',
  // 注册组件
  components: {
    Vcode
  },
  // 注册方法
  methods: {
    submit () {
      this.isShow = true
    },
    success (msg) {
      this.isShow = false
      console.log(msg)
    },
    close () {
      this.isShow = false
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      isShow: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">

</style>
