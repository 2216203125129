<!--
  作者：顾先生
-->
<template>
  <div class="app-container">
<!--    路由占位符-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    if (this._isMobile()) {
      this.$router.replace('/move')
    } else {
      this.$router.replace('/')
    }
  }

}
</script>

<style scoped lang="less">

</style>
