<!--
  作者：顾先生
-->
<template>
  <div class="relatedLinks-container">
    <!--          相关链接布局的内容-->
    <div class="related_links">
      <!--              相关链接-->
      <TabColumn>相关链接</TabColumn>
      <!--              相关链接的盒子-->
      <div class="related_links_box">
        <a href="javascript:" @click="btn1">信用中国</a>
        <a href="javascript:" @click="btn2">国家企业信用信息公示系统</a>
        <a href="javascript:" @click="btn3">全国企业信用信息查询平台</a>
        <a href="javascript:" @click="btn4">中雄世纪征信有限公司</a>
      </div>
    </div>
  </div>
</template>

<script>
import TabColumn from '@/components/TabColumn/TabColumn'
export default {
  name: 'RelatedLinks',
  // 注册组件
  components: { TabColumn },
  // 注册方法
  methods: {
    btn1 () {
      window.open('https://www.creditchina.gov.cn/')
    },
    btn2 () {
      window.open('https://bt.gsxt.gov.cn/index.html')
    },
    btn3 () {
      window.open('http://www.chinagsxt.cn/')
    },
    btn4 () {
      window.open('https://zhongqixin360.com/')
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
// 相关链接的布局
.related_links{
  margin-top:120px;
  height:100px;
  // 相关链接的盒子布局
  .related_links_box{
    padding-left:150px;
    margin-top:20px;
    height: 52px;
    line-height: 32px;
    a{
      color: #333333;
      font-size: 20px;
      margin-right:70px;
      font-weight: bold;
      transition: all .2s;
      &:hover{
        color: #930000;
      }
    }
  }
}
</style>
