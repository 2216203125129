<!--
  作者：顾先生
-->
<template>
  <div class="head-container">
    <!--顶部 -----区域一 -->
    <div class="top_box">
<!--        头部排版盒子-->
       <div class="top wapper">
<!--         头部左侧排版盒子-->
         <div class="top_left">中企信立信企业计划官方网站(立信企业服务平台)</div>
<!--         头部右侧排版盒子-->
         <div class="top_right">
           <ul>
             <li @click="lixinqiyejiaIsShow(1)">中企信立信企业计划</li>
             <li @click="lixinqiyejiaIsShow(2)">企业公示</li>
             <li @click="lixinqiyejiaIsShow(3)">申请加入</li>
             <li @click="lixinqiyejiaIsShow(5)">常见问题</li>
             <li @click="lixinqiyejiaIsShow(5)">联系我们</li>
             <li @click="Login()" class="top_right_lastLi">{{LoginText}}</li>
           </ul>
         </div>
       </div>
    </div>
    <!--头部下背景图 ----- 区域二 -->
    <div class="carousel_box">
<!--      背景图片-->
      <img src="../../assets/images/1.png" alt="中企信立信企业计划" class="carousel_box_img">
<!--      图片上你内容区域-->
      <div class="carousel_content_box wapper">
<!--        头部布局-->
        <div class="carousel_box_top">
          <span class="carousel_box_top_span">全部</span>
          <input type="text" v-model="inputValue" placeholder="请输入您要查询的公司名称" class="carousel_box_top_input">
          <button class="carousel_box_top_btn" @click="searchCompany"><i class="el-icon-search"></i>&nbsp;&nbsp;搜索</button>
          <!--        头部下面的文字布局-->
<!--          <div class="carousel_box_top_font">热门搜索：中企信立信企业计划     信用     企业信用     </div>-->
        </div>

<!--        左侧布局-->
        <div class="carousel_box_left">
<!--          Logo图-->
          <div class="left">
            <img src="../../assets/images/3.png" alt="中企信立信企业计划" class="left_img">
          </div>
<!--          文字-->
          <div class="right">
            <div class="top">
              中企信立信企业计划
            </div>
            <div class="bottom">让信用更有价值</div>
          </div>
        </div>
<!--         右侧布局-->
<!--        <div class="carousel_box_right">-->
<!--          <p class="carousel_box_right_p1">加入中企信立信企业计划有什么用？</p>-->
<!--          <p class="carousel_box_right_p2">-->
<!--            1、可获得立信企业家专属荣誉牌匾，将好的信用形象展现给客户！荣誉牌匾是对您企业从成立以来信用的认可，更体现您持续守信经营的意愿；-->
<!--          </p>-->
<!--          <p class="carousel_box_right_p3">-->
<!--            2、可获得立信企业家公示服务，可在网上展示信用形象。在立信企业家官网查询到企业的信用信息；-->
<!--          </p>-->
<!--          <p class="carousel_box_right_p4">-->
<!--            3、可让信用更有价值。让到访公司的客户一眼就能看到您是信用企业，降低信用宣传的成本。-->
<!--          </p>-->
<!--        </div>-->
<!--        底部布局-->
<!--        <div class="carousel_box_bottom">已有  <span class="carousel_box_bottom_font">888888888</span>  家企业加入了中企信立信企业计划</div>-->
      </div>
    </div>
    <!--导航（轮播图区域） -----区域三 -->
    <div class="navigation_box">
<!--       内容-->
      <div class="navigation wapper">
<!--        Tab选项卡-->
        <ul class="navigation_ul">
          <li class="navigation_li" ref="liChecked" v-for="(item,index) in ['首页','中企信立信企业计划','立信公示','我要加入','牌匾邮寄进度查询','关于我们']" :key="index" @click="handleClick(index)" :class="{active:index === current}">{{item}}</li>
        </ul>
<!--        组件显示隐藏-->

<!--        首页导航栏显示-->
        <div v-show="current === 0">
<!--          Tab栏导航组件-->
<!--          <TabColumn>新闻报道</TabColumn>-->
          <div class="lunBo_box" style="display: none">
<!--            轮播图组件-->
            <Carousel></Carousel>
          </div>
          <!--主体 ----- 区域四 -->
          <div v-show="current === 0">
<!--      企业公示以及企业监督的布局内容-->
            <div class="subject">
              <!--              公示左侧-->
              <div class="subject_left">
                <TabColumn>
                  企业公示
                </TabColumn>
                <p class="subject_supervise_p">举报失信行为</p>
                <!--企业公示内容-->
                <div class="subjectOne wapper" >
                  <!--                  企业公示的头部-->
                  <div class="subject_top"><span class="subject_top_span">企业信息更新可能延迟，如果有信息勘误，请及时联系我们。</span></div>
                  <!--                  企业公示的公司渲染-->
                  <div class="company_left" ref="contentRef">
                    <!--                    公司信息表格-->
                    <el-table
                      :data="tableData"
                      stripe
                      style="width: 100%;">
                      <el-table-column
                        prop="zs_company_name"
                        label="公司名称"
                        width="300"
                        align="center">
                      </el-table-column>
                      <el-table-column
                        prop="zs_company_code"
                        label="统一社会信用代码/注册号"
                        align="center">
                      </el-table-column>
                      <el-table-column
                        prop="zs_riqi"
                        label="公司加入时间"
                        width="180"
                        align="center">
                      </el-table-column>
                      <el-table-column
                        prop="zs_qixian"
                        label="有效日期"
                        align="center">
                      </el-table-column>
                      <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                          <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)">查看</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <!--                  企业公示的底部更多-->
                  <div class="company_bottom_font" @click="nextPage"><span class="company_bottom_font_span">下一页 >></span></div>
                </div>
              </div>
            </div>
            <div class="subject">
              <!--              公示左侧-->
              <div class="subject_left">
                <TabColumn>
                  名片公示
                </TabColumn>
                <p class="subject_supervise_p" style="opacity: 0">举报失信行为</p>
                <!--企业公示内容-->
                <div class="subjectOne wapper" >
                  <!--                  企业公示的头部-->
                  <div class="subject_top"><span class="subject_top_span">名片信息更新可能延迟，如果有信息勘误，请及时联系我们。</span></div>
                  <!--                  企业公示的公司渲染-->
                  <div class="company_left" ref="contentRef">
                    <!--                    公司信息表格-->
                    <el-table
                      :data="tableData2"
                      stripe
                      style="width: 100%;">
                      <el-table-column
                        prop="zs_company_name"
                        label="公司名称"
                        width="300"
                        align="center">
                      </el-table-column>
                      <el-table-column
                        prop="zs_company_code"
                        label="统一社会信用代码/注册号"
                        align="center">
                      </el-table-column>
                      <el-table-column
                        prop="zs_riqi"
                        label="公司加入时间"
                        width="180"
                        align="center">
                      </el-table-column>
                      <el-table-column
                        prop="zs_qixian"
                        label="有效日期"
                        align="center">
                      </el-table-column>
                      <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                          <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete1(scope.$index, scope.row)">查看</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <!--                  企业公示的底部更多-->
<!--                  <div class="company_bottom_font" @click="nextPage"><span class="company_bottom_font_span" style="display: none;">下一页 >></span></div>-->
                </div>
              </div>
            </div>
<!--      中企信立信企业计划的布局内容-->
            <div class="plan wapper">
              <TabColumn>中企信立信企业计划</TabColumn>
<!--              中企信立信企业计划的内容-->
              <div class="plan_box wapper">
<!--                内容填充-->
                <div class="plan_box1">
<!--                  <div class="plan_box1_top">-->
<!--                    <img src="../../assets/images/1.png" alt="">-->
<!--                  </div>-->
                  <div class="plan_box1_middle">
                    <p class="plan_box1_middle_p1">什么是中企信立信企业计划</p>
                    <p class="plan_box1_middle_p2">
                      中企信立信企业计划是由全国企业信用查询平台-中企信发起，为有意愿建立信用形象，推动企业发展和社会信用体系建设的中企信立信企业计划。
                    </p>
                  </div>
                  <div class="plan_box1_btn" @click="lixinqiyejiaIsShow(1)">了解更多</div>
                </div>
                <div class="plan_box1">
<!--                  <div class="plan_box1_top">-->
<!--                    <img src="../../assets/images/1.png" alt="">-->
<!--                  </div>-->
                  <div class="plan_box1_middle">
                    <p class="plan_box1_middle_p1">中企信立信企业计划加入标准</p>
                    <p class="plan_box1_middle_p2">
                     依法在中国大陆注册的企业，成立至申请之日止不存在以下失信行为：失信被执行人记录、被执行人记录、近两年内存在行政处罚记录、等失信行为。
                    </p>
                  </div>
                  <div class="plan_box1_btn" @click="lixinqiyejiaIsShow(3)">了解更多</div>
                </div>
                <div class="plan_box1">
<!--                  <div class="plan_box1_top">-->
<!--                    <img src="../../assets/images/1.png" alt="">-->
<!--                  </div>-->
                  <div class="plan_box1_middle">
                    <p class="plan_box1_middle_p1">中企信立信企业计划提供的服务</p>
                    <p class="plan_box1_middle_p2">
                      实体荣誉牌匾，加入中企信立信企业计划的企业，可获得由中企信立信企业计划定制的实体荣誉牌匾。获得中企信立信企业计划官网公示，展示信用形象。
                    </p>
                  </div>
                  <div class="plan_box1_btn" @click="lixinqiyejiaIsShow(1)">了解更多</div>
                </div>
              </div>
            </div>
<!--      相关链接的公共组件-->
            <RelatedLinks></RelatedLinks>
          </div>
        </div>
<!--        中企信立信企业计划显示-->
        <div v-show="current === 1">
          <div class="Lixin wapper">
            <!--          Tab栏导航组件-->
            <TabColumn>中企信立信企业计划</TabColumn>
            <div class="Lixin_box">
<!--              左侧导航栏选项-->
              <div class="Lixin_left" @click="default_isOk">
<!--                <div class="Lixin_btn1 default" ref="default">关于中企信立信企业计划</div>-->
                <div class="Lixin_btn1 default" ref="default">关于中企信立信企业计划</div>
<!--                <div class="Lixin_btn1">立信/守信承诺企业</div>-->
<!--                <div class="Lixin_btn2">-->
<!--                  <div class="span">服务规范</div>-->
<!--                  <div class="span">加入条件</div>-->
<!--                  <div class="span_select">-->
<!--                    <div class="span_select_span1" @click="select_LixinIsOk"><i :class="icons"></i>服务说明</div>-->
<!--                    <div v-show="select_Lixin">-->
<!--                      <div class="span_select_span1_son">实体荣誉牌匾</div>-->
<!--                      <div class="span_select_span1_son">官方网站展示</div>-->
<!--                      <div class="span_select_span1_son">更多</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
<!--              右侧显示内容-->
              <div class="Lixin_right">
<!--                关于中企信立信企业计划的盒子内容-->
                <div class="lixinqiyejihua" v-if="lixinqiyejiaIsOk">
                  <div class="Lixin_right_box1">
<!--                    <p class="Lixin_right_box1_p1">关于中企信立信企业计划</p>-->
                    <p class="Lixin_right_box1_p1">关于中企信立信企业计划</p>
                    <br>
                    <p class="Lixin_right_box1_p2">
                      <span class="Lixin_right_box1_span1">1、加入中企信立信企业计划有什么用？</span><br>
                      <span class="Lixin_right_box1_span2">(1)、可获得中企信立信企业计划专属荣誉牌匾，将好的信用形象展现给客户！荣誉牌匾是对您企业从成立以来信用的认可，更体现您持续守信经营的意愿；</span><br>
                      <span class="Lixin_right_box1_span2">(2)、可获得中企信立信企业计划公示服务，可在网上展示信用形象。在中企信立信企业计划官网查询到企业的信用信息；</span><br>
                      <span class="Lixin_right_box1_span2">(3)、可让信用更有价值。让到访公司的客户一眼就能看到您是信用企业，降低信用宣传的成本。</span><br><br>
                      <span class="Lixin_right_box1_span1">2、什么是中企信立信企业计划</span><br>
                      <span class="Lixin_right_box1_span2">中企信立信企业计划是由全国企业信用查询平台-中企信发起，为有意愿建立信用形象，推动企业发展和社会信用体系建设的中企信立信企业计划。</span><br><br>
                      <span class="Lixin_right_box1_span1">3、立信企业服务收费参考（实际价格以企业申请加入时收费金额为准） </span><br>
                      <span class="Lixin_right_box1_span2">(1)、中企信立信企业计划按一年个体工商户130、企业130元收费（包括审核以及木牌的制作和快递费用），次年到期前会联系经营主体，如还需要继续办理服务，中企信会对经营主体信用情况进行审核，符合标准可继续办理，如过期不办理，中企信立信企业计划提供的服务会全部失效；</span> <br>
                      <span class="Lixin_right_box1_span2">(2)、同时中企信立信企业计划还提供金属材质牌匾供企业选择，如选择金属牌匾，个体需要再支付48元、企业需要再支付50元制作费用。</span> <br><br>
                      <span class="Lixin_right_box1_span1">4、中企信立信企业计划加入标准 </span><br>
                      <span class="Lixin_right_box1_span2">申请加入中企信立信企业计划必须满足以下条件：</span> <br>
                      <span class="Lixin_right_box1_span2">(1)、依法在中国大陆注册的企业；</span> <br>
                      <span class="Lixin_right_box1_span2">(2)、成立至今不存在以下失信行为：失信被执行人记录、被执行人记录、近两年内存在行政处罚记录、存在重大税务违法记录、重大负面舆情记录、等失信行为。</span> <br><br>
                      <span class="Lixin_right_box1_span1">5、中企信立信企业计划提供的服务 </span><br>
                      <span class="Lixin_right_box1_span2">(1)、实体荣誉牌匾，加入中企信立信企业计划的企业，可获得由中企信立信企业计划定制的实体荣誉牌匾。 </span><br>
                      <span class="Lixin_right_box1_span2">牌匾可根据用户的需求，在室内/室外悬挂或摆放或拍照放在企业网站上。让“上门”客户看到实体牌匾更容易信任企业，让信用更有价值 </span> <br>
                      <img src="../../assets/images/lone.png" alt="牌匾" class="Lixin_right_box1_img"> <img src="../../assets/images/ltwo.png" alt="牌匾" class="Lixin_right_box1_img2"> <img src="../../assets/images/lthree.png" alt="牌匾" class="Lixin_right_box1_img3"> <img src="../../assets/images/lfour.png" alt="牌匾" class="Lixin_right_box1_img4"><br>
                      <span class="Lixin_right_box1_span2">(2)、中企信立信企业计划官方网站公示，加入中企信立信企业计划的企业，可获得中企信立信企业计划官网公示，展示信用象形。</span><br>
                      <img src="../../assets/images/zhanshi2.png" alt="展示" class="zhanshi2LLb2"> <img src="../../assets/images/zhanshi3.png" alt="展示" class="zhanshi2LLb1"><br><br>
                      <img src="../../assets/images/zhanshi1.png" alt="展示" class="zhanshi2LLb3"><br>
                      <span class="Lixin_right_box1_span1">6、 发现失信和违规信息后的处理规范</span><br>
                      <span class="Lixin_right_box1_span2">加入中企信立信企业计划的企业如果被检测到违背准入条件信息或行为，中企信立信企业计划将中止或终止服务，具体规范如下：</span>
                      <br>
                      <span class="Lixin_right_box1_span2">(1) 、异常经营、用户投诉、吊销、重大舆情等行为，将中止服务，并在牌匾二维码扫码页面显示中止状态及情况说明。如在服务期限内企业处理完结以上行为，并重新达到中企信立信企业计划准入条件时，中企信立信企业计划将恢复服务，并撤销牌匾二维码扫码页面取消终止状态。</span>
                      <br>
                      <span class="Lixin_right_box1_span2">(2) 、失信被执行人、重大税务违法记录、等行为，将直接终止服务，不再恢复并在匾二维码扫码页面显示终止状态及情况说明。</span>
                    </p>
                  </div>
<!--                  <div class="Lixin_right_box2">-->
<!--                    <p class="Lixin_right_box2_p1">加入立信企业家计划有什么用？</p>-->
<!--                    <br>-->
<!--                    <p class="Lixin_right_box2_p2">1、可获得立信企业家专属荣誉牌匾，将好的信用形象展现给客户！荣誉牌匾是对您企业从成立以来信用的认可，更体现您持续守信经营的意愿；</p>-->

<!--                    <p class="Lixin_right_box2_p2">2、可获得立信企业家公示服务，可在网上展示信用形象。在立信企业家官网查询到企业的信用信息；</p>-->

<!--                    <p class="Lixin_right_box2_p2">3、可让信用更有价值。让到访公司的客户一眼就能看到您是信用企业，降低信用宣传的成本。</p>-->
<!--                  </div>-->
                </div>
<!--                服务规范的盒子内容-->
                <div class="fuwuguifan" v-if="fuwuguifanIsOk">
                  <p class="fuwuguifan_p1">服务规范</p>
                  <br>
                  <div class="fuwuguifan_box1">
                    <p>前言</p>
                    <br>
                    <p>
                      为响应“守信激励、失信惩戒”的号召，促进中小企业（含个体经营者，以下统称“经营主体或主体”）健康发展，营造良好的营商环境，中雄世纪征信有限公司负责运营的全国企业信用信息大数据平台“中企信”向经营主体推出以守信为前提的中企信立信企业计划服务。公司以解决中小企业难点和诉求为核心，依据《征信业管理条例》、《征信机构管理办法》等法律、法规开展业务，拟定本服务规范。
                    </p>
                    <br>
                    <p>
                      一、适用范围 <br>
                      本规范规定了主体加入中企信立信企业计划的基本条件，中企信立信企业计划服务内容与收费标准，加入中企信立信企业计划的主体信用动态的监控范围，取消立信企业资格的规范和处理办法，对立信企业投诉的处理办法。
                    </p>
                    <br>
                    <p>
                      二、中企信立信企业计划准入条件 <br>
                      1、依法在中国大陆注册的企业；<br>
                      2、成立至今不存在以下失信行为：失信被执行人记录、被执行人记录、近两年内存在行政处罚记录、存在重大税务违法记录、重大负面舆情记录、<br>
                      3、签署了《守信承诺书》。
                    </p>
                    <br>
                    <p>
                      三、中企信立信企业计划服务内容 <br>
                      1、中企信立信企业计划牌匾 <br>
                      中企信立信企业计划牌匾是中小企业加入中企信立信企业计划的标志，是对企业诚信经营意愿的鼓励。企业加入中企信立信企业计划即可获取实体中企信立信企业计划的牌匾及线上守信承诺书。实体中企信立信企业计划牌匾由用户悬挂（放置）在经营场所。消费者通过扫描中企信立信企业计划牌匾中的二维码，可以看到该企业守信承诺内容，同时可反馈问题，包括点赞、投诉、举报等。<br>
                      2、中企信立信企业计划官网公示 <br>
                      网民可通过中企信立信企业计划官网，查询每一个立信企业的实时状态，包括：守信承诺书、证书标号、加入中企信立信企业计划时间、检测时间、有效时间。<br>
                      3、 服务流程 <br>
                      <img src="../../assets/images/8.png" alt="服务流程">
                    </p>
                    <br>
                    <p>
                      四、 中企信立信企业计划收费标准 <br>
                      立信企业/商户按一年90元收费（包括信息监测、审核以及木牌的制作和快递费用）。<br>
                      同时中企信立信企业计划还提供金属材质牌匾供企业选择，如选择金属立信牌匾，须再支付48元制作费
                    </p>
                    <br>
                    <p>
                      五、信用信息动态监控 <br>
                      加入中企信立信企业计划的企业在服务期内将检测企业信用动态，包括且不限于：<br>
                      1、 企业经营信息<br>
                      2、 企业信用信息<br>
                      3、 企业舆情信息<br>
                      4、 通过中企信立信企业计划对企业的投诉信息
                    </p>
                    <br>
                    <p>
                      六、 发现失信和违规信息后的处理规范 <br>
                      加入中企信立信企业计划的企业如被检测到违背准入条件信息或行为，中企信立信企业计划将中止或终止服务，具体规范如下：<br>
                      1、 经营异常、用户投诉、吊销、重大舆情等行为，将中止服务，并在牌匾二维码扫码页面显示中止状态及情况说明。如在服务期限内企业处理完结以上行为，并重新达到中企信立信企业计划准入条件时，中企信立信企业计划将恢复服务，并撤销牌匾二维码扫码页面取消终止状态。<br>
                      2、 失信被执行人、重大税务违法记录、等行为，将直接终止服务，不再恢复并在匾二维码扫码页面显示终止状态及情况说明。
                    </p>
                    <br>
                    <p>
                      七、投诉处理办法<br>
                      中企信立信企业计划将对通过中企信立信企业计划产生的投诉、举报进行动态管理。经核实如投诉、举报内容确涉及违背《守信承诺书》情况，中企信立信企业计划将中止服务，并在牌匾二维码扫码页面显示中止状态及情况说明。
                    </p>
                  </div>
                </div>
<!--                加入条件的盒子内容-->
                <div class="jiarutiaojian" v-if="jiatutiaojian">
                  <div class="jiarutiaojian_box">
                    <p>申请加入立信/守信承诺企业必须满足以下条件</p>
                    <p>1、依法在中国大陆注册的企业；</p>
                    <p>2、成立至今不存在以下失信行为：失信被执行人记录、被执行人记录、<br>近两年内存在行政处罚记录、存在重大税务违法记录、重大负面舆情记录、等失信行为。</p>
                    <p>3、签署了《守信承诺书》。</p>
                  </div>
                </div>
<!--                实体荣誉牌匾的盒子内容-->
                <div class="rongyupaibian" v-if="rongyupaibian">
                  <h2 class="rongyupaibian_h2">荣誉牌匾</h2>
                </div>
<!--                官方网站展示的盒子内容-->
                <div class="guanfangwangzhan" v-if="guanfangwangzhan">
                  <h2 class="guanfangwangzhan_h2">官方网站展示</h2>
                </div>
<!--                更多的盒子内容-->
                <div class="gengduo" v-if="gengduo">
                  <h2 class="gengduo_h2">更多</h2>
                </div>
              </div>
            </div>
<!--            相关链接-->
            <div class="Lixin_RelatedLinks">
              <RelatedLinks></RelatedLinks>
            </div>
          </div>
        </div>
<!--        企业公示显示-->
        <div v-show="current === 2">
          <div class="GongShi wapper">
            <!--          Tab栏导航组件-->
            <TabColumn>企业公示</TabColumn>
<!--            公示盒子里面的内容-->
            <div class="GongShibox">
<!--              头部字体-->
              <div class="GongShitop" @click="GongShi">
                <span class="GongShi_span GongshiDefault"><i :class="GongShiIcons" v-if="iconIsOk1"></i>企业公示</span>
              </div>
<!--              公司的主体内容1-->
              <div class="GongShi_box1" v-if="GongShi_box1">
                <div class="GongShi_content" v-for="(item,index) in publicityData" :key="index">
                  <!--                左侧内容-->
                  <div class="left" @click="enterprisePublicity(item.zs_company_name)">
                    <p class="left_p1">{{ item.zs_company_name }}</p>
                    <p class="left_p2"><span class="left_span1">有效期:1年</span><span class="left_span1">加入时间:{{ item.zs_riqi }}</span><span class="left_span2">统一社会信用代码 {{ item.zs_company_code }}</span></p>
                  </div>
                  <!--                右侧内容-->
<!--                  <div class="right">-->
<!--                    <p class="right_p1">{{ item }}</p>-->
<!--                    <p class="right_p2"><span class="right_span1">有效期:1年</span><span class="right_span1">加入时间:2021-05-08</span><span class="right_span2">统一社会信用代码 91440402MA55MC9T8F</span></p>-->
<!--                  </div>-->
                </div>
                <!--              下一页内容-->
                <div class="paging" @click="nextPagepublicity" v-show="XIayiYe">下一页 >></div>
              </div>
            </div>
            <!--            相关链接-->
            <div class="Lixin_RelatedLinks">
              <RelatedLinks></RelatedLinks>
            </div>
          </div>
        </div>
<!--        申请加入显示-->
        <div v-show="current === 3">
          <div class="Lixin wapper">
            <!--          Tab栏导航组件-->
            <TabColumn>申请加入</TabColumn>
            <div class="Lixin_box">
              <!--              左侧导航栏选项-->
              <div class="Lixin_left">
                <div class="Lixin_btn1 default">申请加入立信企业</div>
              </div>
              <!--              右侧显示内容-->
              <div class="Lixin_right">
                <div class="ShenQing">
                  <p class="ShenQing_p1">申请加入中企信立信企业计划</p>
                  <p class="ShenQing_p2">如您的企业满足加入 <a href="javascript:">中企信立信企业计划</a> 标准 ，可在线申请，扫描下方二维码完成加入流程。</p>
                  <div class="ShenQing_img">
                    <img src="../../assets/images/kefu.png" class="ShenQing_img_img">
                  </div>
                </div>
              </div>
            </div>
            <!--            相关链接-->
            <div class="Lixin_RelatedLinks">
              <RelatedLinks></RelatedLinks>
            </div>
          </div>
        </div>
<!--        进度查询显示-->
        <div v-show="current === 4">
          <div class="Lixin wapper">
            <!--          Tab栏导航组件-->
            <TabColumn>进度查询</TabColumn>
            <div class="Lixin_box">
              <!--              左侧导航栏选项-->
              <div class="Lixin_left">
                <div class="Lixin_btn1 default">查看牌匾邮寄进度</div>
              </div>
              <!--              右侧显示内容-->
              <div class="Lixin_right">
                <div class="YouJI">
                  <p class="YouJI_p1">我已加入中企信立信企业计划，点击下方查看牌匾邮寄进度。</p>
                  <p class="YouJI_p2"><span class="YouJI_span1" @click="kuaidi">牌匾邮寄进度查询 <i class="el-icon-search"></i> </span></p>
                </div>
              </div>
            </div>
            <!--            相关链接-->
            <div class="Lixin_RelatedLinks">
              <RelatedLinks></RelatedLinks>
            </div>
          </div>
        </div>
<!--        关于我们显示-->
        <div v-show="current === 5">
          <div class="Lixin wapper">
            <!--          Tab栏导航组件-->
            <TabColumn>关于我们</TabColumn>
            <div class="Lixin_box">
              <!--              左侧导航栏选项-->
              <div class="Lixin_left" @click="guanyuwomen">
                <div class="guanyu_btn1 guanyuDefault">关于我们</div>
                <div class="guanyu_btn1">联系我们</div>
                <div class="guanyu_btn1">常见问题</div>
              </div>
              <!--              关于我们内容1-->
              <div class="Lixin_right" v-if="GuanyuWoMeng1">
                <div class="YouJI">
                  <p class="YouJI_p1">关于中企信立信企业计划</p>
                  <p class="guanyu_p2">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;俗话说：金杯银杯不如老百姓的口碑。自古以来，讲信用的人总是受到的人们的欢迎和赞颂，如“一诺千金”、“言而有信”等都传颂着信用的重要性。<br><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;正所谓是“人无信不立，业无信不兴”，所以人与人之间交往，做生意，信用无疑是比较重要的一个因素。中企信立信企业计划是由中企信为有意愿建立信用形象，推动企业发展和社会信用体系建设的一项信用计划。<br><br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中企信立信企业计划旨在“让信用更有价值”，依据 公平、公正、公开的方法对企业信用进行检测并公示结果，用以降低信息不对称产生的成本，让企业信用形象得到提升，从而达到“褒扬诚信、惩戒失信”的目标。<br>
                    <br>
                  </p>
                </div>
              </div>

              <!--              关于我们内容2-->
              <div class="Lixin_right" v-if="GuanyuWoMeng2">
                <div class="YouJI">
                  <p class="YouJI_p1">联系我们</p>
                  <p class="neirong2_guanyu_img">
                    <img src="../../assets/images/lianxiwomeng.png" alt="联系我们">
                  </p>
                  <p class="neirong2_guanyu_p2">
                    运营地址：（中国）河北省保定市莲池区东金庄乡七一东路1588号未来金融港1号楼514室 <br>
                    联系电话：400-0642-818<br>
                    电子邮箱：zhongqixin360@163.com<br>
                  </p>
                </div>
              </div>

              <!--              关于我们内容3-->
              <div class="Lixin_right" v-if="GuanyuWoMeng3">
                <div class="YouJI">
                  <p class="YouJI_p1">常见问题</p>
                  <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="什么是中企信立信企业计划？" name="1">
                      <div style="background-color:#f4f4f4;">中企信立信企业计划是由全国企业信用查询平台-中企信发起，为有意愿建立信用形象，推动企业发展和社会信用体系建设的中企信立信企业计划。</div>
                    </el-collapse-item>
                    <el-collapse-item title="中雄世纪征信有限公司是什么？和中企信立信企业计划有什么关系?" name="2">
                      <div style="background-color:#f4f4f4;">中雄世纪征信有限公司（以下简称“中企信”）负责运营的全国企业信用查询平台通过“中企信立信企业计划”向营业主体推出有建立信用意愿为前提的中企信立信企业计划服务。</div>
                      <div style="background-color:#f4f4f4;">中企信立信企业计划是中雄世纪征信有限公司面向中小企业提供的一项服务。中企信立信企业计划旨在“让信用更有价值”，依据 公平、公正、公开的方法对企业信用进行检测并公示结果，用以降低信息不对称产生的成本，让企业信用形象得到提升，从而达到“褒扬诚信、惩戒失信”的目标。</div>
                    </el-collapse-item>
                    <el-collapse-item title="加入中企信立信企业计划有什么用？如何加入？" name="3">
                      <div style="background-color:#f4f4f4;">(1)、可获得中企信立信企业计划专属荣誉牌匾，将好的信用形象展现给客户！荣誉牌匾是对您企业从成立以来信用的认可，更体现您持续守信经营的意愿；</div>
                      <div style="background-color:#f4f4f4;">(2)、可获得中企信立信企业计划公示服务，可在网上展示信用形象。在中企信立信企业计划官网查询到企业的信用信息；</div>
                      <div style="background-color:#f4f4f4;">(3)、可让信用更有价值。让到访公司的客户一眼就能看到您是信用企业，降低信用宣传的成本。</div>
                      <div style="background-color:#f4f4f4;">如您的企业满足加入 <span style="color: red;">中企信立信企业计划</span> 标准 ，可在线申请，扫描下方二维码完成加入流程。</div>
                      <img src="../../assets/images/kefu.png" class="wenti827">
                    </el-collapse-item>
                    <el-collapse-item title="中企信立信企业计划收费标准是什么？" name="4">
                      <div style="background-color:#f4f4f4;">(1)、中企信立信企业计划按一年个体工商户130、企业130元收费（包括审核以及木牌的制作和快递费用），次年到期前会联系经营主体，如还需要继续办理服务，中企信会对经营主体信用情况进行审核，符合标准可继续办理，如过期不办理，中企信立信企业计划提供的服务会全部失效；</div>
                      <div style="background-color:#f4f4f4;">(2)、同时中企信立信企业计划还提供金属材质牌匾供企业选择，如选择金属牌匾，个体需要再支付48元、企业需要再支付50元制作费用。</div>
                    </el-collapse-item>
                    <el-collapse-item title="加入中企信立信企业计划后荣誉牌匾什么时候可以收到？" name="5">
                      <div style="background-color:#f4f4f4;">经营主体购买中企信立信企业计划服务须进行实名核验，须按要求提交完整、真实的资料且履行相关程序。公司将在三个工作日内完成核验，并在核验完成后十个工作日进行牌匾的制作和邮寄。如因不可抗力或其它客观原因无法完成的，公司将全额退还服务费用。</div>
                    </el-collapse-item>
                    <el-collapse-item title="为什么我的立信牌匾会有效期？" name="6">
                      <div style="background-color:#f4f4f4;">加入中企信立信企业计划的企业须经过备案信用服务机构信用检测且符合中企信立信企业计划加入条件规范，有效周期为一年。满一年后如企业继续加入中企信立信企业计划，会再次经过备案信用服务机构信用检测判断是否符合中企信立信企业计划加入条件规范，如符合可继续加入，并更新牌匾有效期重新寄送给您。</div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
            </div>
            <!--            相关链接-->
            <div class="Lixin_RelatedLinks">
              <RelatedLinks></RelatedLinks>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--          首页灰色背景1-->
    <div class="bgc" v-if="current===0"></div>
    <!--          首页灰色背景2-->
    <div class="bgc two" v-if="current===0"></div>
    <!--          首页灰色背景3-->
    <div class="bgc two" v-if="current===0"></div>
    <!--          首页灰色背景4-->
    <div class="four" v-if="current===0"></div>

<!--    &lt;!&ndash;    中企信立信企业计划灰色背景1&ndash;&gt;-->
<!--    <div class="LIXIN_bgc" v-if="current===1"></div>-->
    <!--    中企信立信企业计划灰色背景2-->
    <div class="LIXIN_bgc bom1" v-if="current===1"></div>
    <!--    中企信立信企业计划灰色背景3-->
    <div class="LIXIN_bgc bom2" v-if="current===1"></div>

    <!--    企业公示灰色背景2-->
    <div class="LIXIN_bgc bom1" v-if="current===2"></div>
    <!--    企业公示灰色背景3-->
    <div class="LIXIN_bgc bom2" v-if="current===2"></div>

    <!--    申请加入灰色背景2-->
    <div class="LIXIN_bgc bom1" v-if="current===3"></div>
    <!--    申请加入灰色背景3-->
    <div class="LIXIN_bgc bom2" v-if="current===3"></div>

    <!--    进度查询灰色背景2-->
    <div class="LIXIN_bgc bom1" v-if="current===4"></div>
    <!--    进度查询灰色背景3-->
    <div class="LIXIN_bgc bom2" v-if="current===4"></div>

    <!--    关于我们灰色背景2-->
    <div class="LIXIN_bgc bom1" v-if="current===5"></div>
    <!--    关于我们灰色背景3-->
    <div class="LIXIN_bgc bom2" v-if="current===5"></div>

    <!--            首页底部内容-->
    <div class="bottom">
      <Bottom></Bottom>
    </div>
    <!--    旋转图片验证-->
    <div class="MoNiBox" v-show="HuaIsShow2">
      <div class="MoNi" style="user-select: none">
        <drag-verify-img-rotate
          ref="dragVerify"
          :width = 200
          :imgsrc="Img3"
          :isPassing.sync="isPassing4"
          text="请按住滑块拖动"
          successText="验证通过"
          handlerIcon="el-icon-d-arrow-right"
          successIcon="el-icon-circle-check"
          @passcallback="success2"
        >
        </drag-verify-img-rotate>
      </div>
    </div>
    <!--    旋转图片验证2-->
    <div class="MoNiBox" v-show="HuaIsShow">
      <div class="MoNi" style="user-select: none">
        <drag-verify-img-rotate
          ref="dragVerify22"
          :width = 200
          :imgsrc="Img3"
          :isPassing.sync="isPassing5"
          text="请按住滑块拖动"
          successText="验证通过"
          handlerIcon="el-icon-d-arrow-right"
          successIcon="el-icon-circle-check"
          @passcallback="success"
        >
        </drag-verify-img-rotate>
      </div>
    </div>
    <!--    旋转图片验证2-->
    <div class="MoNiBox" v-show="HuaIsShow3">
      <div class="MoNi" style="user-select: none">
        <drag-verify-img-rotate
          ref="dragVerify33"
          :width = 200
          :imgsrc="Img3"
          :isPassing.sync="isPassing6"
          text="请按住滑块拖动"
          successText="验证通过"
          handlerIcon="el-icon-d-arrow-right"
          successIcon="el-icon-circle-check"
          @passcallback="success3"
        >
        </drag-verify-img-rotate>
      </div>
    </div>
<!--    <Vcode :show="HuaIsShow" @success="success" @close="close"></Vcode>-->
<!--    <Vcode :show="HuaIsShow2" @success="success2" @close="close2"></Vcode>-->
  </div>
</template>

<script>
// 引入每行介绍栏组件
import TabColumn from '@/components/TabColumn/TabColumn'
// 引入轮播图组件
import Carousel from '@/components/Carousel/Carousel'
// 引入Bottom组件
import Bottom from '@/components/Bottom/Bottom'
// 引入旋转图片验证组件
import dragVerifyImgRotate from 'vue-drag-verify-img-rotate'
// 引入相关链接组件
import RelatedLinks from '@/components/RelatedLinks/RelatedLinks'
import axios from 'axios'

export default {
  name: 'Head',
  components: {
    TabColumn,
    Carousel,
    Bottom,
    RelatedLinks,
    dragVerifyImgRotate
  },
  methods: {
    // 登录界面
    Login () {
      if (this.LoginText === '登录') {
        this.$router.push({
          path: '/Login'
        })
      } else {
        this.$notify.error({
          title: '失败！',
          message: '您已登录！无需重复登录！！！'
        })
      }
    },
    // 跳转快递官网
    kuaidi () {
      window.open('https://www.kuaidi100.com/')
    },
    // 公司表格的查看按钮
    handleDelete (index, row) {
      // console.log(row.zs_company_name)
      this.certificate(row.zs_company_name)
    },
    // 公司名片表格的查看按钮
    handleDelete1 (index, row) {
      this.certificate2(row.zs_id)
    },
    handleClick (index) {
      this.current = index
      window.scrollTo({
        top: document.querySelector('.navigation_ul').offsetTop,
        behavior: 'smooth'
      })
    },
    // 下拉显示下拉菜单的选项方法
    select_LixinIsOk () {
      this.select_Lixin = !this.select_Lixin
      if (this.select_Lixin) {
        this.icons = 'el-icon-caret-bottom'
      } else {
        this.icons = 'el-icon-caret-right'
      }
    },
    // 中企信立信企业计划的下拉框的冒泡事件
    default_isOk (e) {
      if (e.target.innerHTML === '关于中企信立信企业计划') {
        document.querySelector('.default').classList.remove('default')
        e.target.classList.add('default')
        this.lixinqiyejiaIsOk = true
        this.fuwuguifanIsOk = false
        this.jiatutiaojian = false
        this.rongyupaibian = false
        this.guanfangwangzhan = false
        this.gengduo = false
      } else if (e.target.innerHTML === '服务规范') {
        document.querySelector('.default').classList.remove('default')
        e.target.classList.add('default')
        this.lixinqiyejiaIsOk = false
        this.fuwuguifanIsOk = true
        this.jiatutiaojian = false
        this.rongyupaibian = false
        this.guanfangwangzhan = false
        this.gengduo = false
      } else if (e.target.innerHTML === '加入条件') {
        document.querySelector('.default').classList.remove('default')
        e.target.classList.add('default')
        this.lixinqiyejiaIsOk = false
        this.fuwuguifanIsOk = false
        this.jiatutiaojian = true
        this.rongyupaibian = false
        this.guanfangwangzhan = false
        this.gengduo = false
      } else if (e.target.innerHTML === '实体荣誉牌匾') {
        document.querySelector('.default').classList.remove('default')
        e.target.classList.add('default')
        this.lixinqiyejiaIsOk = false
        this.fuwuguifanIsOk = false
        this.jiatutiaojian = false
        this.rongyupaibian = true
        this.guanfangwangzhan = false
        this.gengduo = false
      } else if (e.target.innerHTML === '官方网站展示') {
        document.querySelector('.default').classList.remove('default')
        e.target.classList.add('default')
        this.lixinqiyejiaIsOk = false
        this.fuwuguifanIsOk = false
        this.jiatutiaojian = false
        this.rongyupaibian = false
        this.guanfangwangzhan = true
        this.gengduo = false
      } else if (e.target.innerHTML === '更多') {
        document.querySelector('.default').classList.remove('default')
        e.target.classList.add('default')
        this.lixinqiyejiaIsOk = false
        this.fuwuguifanIsOk = false
        this.jiatutiaojian = false
        this.rongyupaibian = false
        this.guanfangwangzhan = false
        this.gengduo = true
      }
    },
    // 企业公示的冒泡事件
    GongShi (e) {
      if (e.target.innerText === '企业公示') {
        document.querySelector('.GongshiDefault').classList.remove('GongshiDefault')
        e.target.classList.add('GongshiDefault')
        this.iconIsOk1 = true
        this.iconIsOk2 = false
        this.GongShi_box1 = true
        this.GongShi_box2 = false
      } else if (e.target.innerText === '立信监测') {
        document.querySelector('.GongshiDefault').classList.remove('GongshiDefault')
        e.target.classList.add('GongshiDefault')
        this.iconIsOk2 = true
        this.iconIsOk1 = false
        this.GongShi_box1 = false
        this.GongShi_box2 = true
      }
    },
    // 关于我们模块的冒泡事件
    guanyuwomen (e) {
      console.log(e)
      if (e === 3 || e === 4) {
        document.querySelector('.guanyuDefault').classList.remove('guanyuDefault')
        const AddClass = document.querySelectorAll('.guanyu_btn1')
        if (e === 3) {
          AddClass[1].classList.add('guanyuDefault')
          this.GuanyuWoMeng1 = false
          this.GuanyuWoMeng2 = true
          this.GuanyuWoMeng3 = false
        } else if (e === 4) {
          AddClass[2].classList.add('guanyuDefault')
          this.GuanyuWoMeng1 = false
          this.GuanyuWoMeng2 = false
          this.GuanyuWoMeng3 = true
        }
      } else {
        if (e.target.innerText === '关于我们') {
          document.querySelector('.guanyuDefault').classList.remove('guanyuDefault')
          e.target.classList.add('guanyuDefault')
          this.GuanyuWoMeng1 = true
          this.GuanyuWoMeng2 = false
          this.GuanyuWoMeng3 = false
        } else if (e.target.innerText === '联系我们') {
          document.querySelector('.guanyuDefault').classList.remove('guanyuDefault')
          e.target.classList.add('guanyuDefault')
          this.GuanyuWoMeng1 = false
          this.GuanyuWoMeng2 = true
          this.GuanyuWoMeng3 = false
        } else if (e.target.innerText === '常见问题') {
          document.querySelector('.guanyuDefault').classList.remove('guanyuDefault')
          e.target.classList.add('guanyuDefault')
          this.GuanyuWoMeng1 = false
          this.GuanyuWoMeng2 = false
          this.GuanyuWoMeng3 = true
        }
      }
    },
    // 点击最顶部的中企信立信企业计划的文字跳转到中部导航中企信立信企业计划
    lixinqiyejiaIsShow (num) {
      this.current = num
      window.scrollTo({
        top: document.querySelector('.navigation_ul').offsetTop,
        behavior: 'smooth'
      })
    },
    // 获取公司信息接口
    Getinformation () {
      axios({
        // 请求方式get或post
        method: 'get',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Plaque/pageMedia',
        // 请求参数
        params: {
          page: this.pageinformation,
          size: 8
        }
      }).then(res => {
        // 成功回调
        this.tableData = res.data.data.data
      })
    },
    // 获取名片信息接口
    GetinMingPian () {
      axios({
        // 请求方式get或post
        method: 'get',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Plaque/pageMedia',
        // 请求参数
        params: {
          page: this.pageinformation2,
          size: 8,
          type: 1
        }
      }).then(res => {
        // 成功回调
        this.tableData2 = res.data.data.data
      })
    },
    // 获取企业公示接口
    Getpublicity () {
      axios({
        // 请求方式get或post
        method: 'get',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Plaque/pageMedia',
        // 请求参数
        params: {
          page: this.pagepublicity,
          size: 14
        }
      }).then(res => {
        // 成功回调
        this.publicityData = res.data.data.data
      })
    },
    // 点击下一页
    nextPage () {
      this.HuaIsShow2 = true
    },
    // 企业公示接口点击下一页
    nextPagepublicity () {
      this.HuaIsShow = true
    },
    success2 () {
      setTimeout(() => {
        this.pageinformation++
        this.Getinformation()
        this.HuaIsShow2 = false
        this.isPassing4 = false
        if (this.$refs.dragVerify !== undefined) {
          this.$refs.dragVerify.reset()
        }
      }, 600)
    },
    close2 () {
      this.HuaIsShow2 = false
    },
    success () {
      setTimeout(() => {
        this.pagepublicity++
        this.Getpublicity()
        this.HuaIsShow = false
        this.XIayiYe = false
        this.isPassing5 = false
        if (this.$refs.dragVerify22 !== undefined) {
          this.$refs.dragVerify22.reset()
        }
      }, 600)
    },
    success3 () {
      setTimeout(() => {
        this.certificate(this.inputValue)
        this.HuaIsShow3 = false
        this.isPassing6 = false
        if (this.$refs.dragVerify33 !== undefined) {
          this.$refs.dragVerify33.reset()
        }
      }, 600)
    },
    close () {
      this.HuaIsShow = false
    },
    // 查看证书
    certificate (companyName) {
      axios({
        // 请求方式get或post
        method: 'post',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Zxrs',
        // 请求参数
        data: {
          title: companyName
        }
      }).then(res => {
        if (res.data.msg === 'success') {
          window.open(`https://www.zhongqixin360.com/Enterprise/index.html?title=${companyName}`)
          // 成功回调
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 查看证书
    certificate2 (companyName) {
      window.location.href = `https://www.zhongqixin360.com/mingpian/index?v=${companyName}`
    },
    // 点击搜索查询公司名称
    searchCompany () {
      if (this.inputValue === '') {
        this.$message({
          showClose: true,
          message: '公司名称不能为空哦~',
          type: 'warning'
        })
      } else {
        this.HuaIsShow3 = true
      }
    },
    // 点击企业公示查看证书
    enterprisePublicity (enterprisePublicityName) {
      this.certificate(enterprisePublicityName)
    }
  },
  props: {},
  data () {
    return {
      LoginText: '登录',
      // 手风琴效果
      activeName: 1,
      isPassing6: false,
      isPassing5: false,
      isPassing4: false,
      Img3: require('@/assets/images/xuanzhuan.png'),
      XIayiYe: true,
      HuaIsShow: false,
      HuaIsShow2: false,
      HuaIsShow3: false,
      // Tab栏选项
      current: 0,
      // 搜索框双向绑定
      inputValue: '',
      // 中企信立信企业计划下拉控制下拉选项的显示隐藏
      select_Lixin: false,
      // 中企信立信企业计划下拉控制的小图标
      icons: 'el-icon-caret-right',
      // 企业公示小图标
      GongShiIcons: 'el-icon-caret-right',
      // 关于立信企业家计划导航的显示隐藏
      lixinqiyejiaIsOk: true,
      // 服务规范的显示隐藏
      fuwuguifanIsOk: false,
      // 加入条件的显示隐藏
      jiatutiaojian: false,
      // 荣誉牌匾的显示隐藏
      rongyupaibian: false,
      // 官方网站展示的显示隐藏
      guanfangwangzhan: false,
      // 更多展示的显示隐藏
      gengduo: false,
      // 企业公示的显示隐藏1
      iconIsOk1: true,
      // 企业公示的显示隐藏1
      iconIsOk2: false,
      // 企业公示的内容显示隐藏1
      GongShi_box1: true,
      // 企业公示的内容显示隐藏2
      GongShi_box2: false,
      // 关于我们内容1
      GuanyuWoMeng1: true,
      // 关于我们内容2
      GuanyuWoMeng2: false,
      // 关于我们内容3
      GuanyuWoMeng3: false,
      // 自动轮播
      timer: undefined,
      // 公司表格
      tableData: [],
      // 公司名片表格
      tableData2: [],
      // 公司页码
      pageinformation: 1,
      // 名片页码
      pageinformation2: 1,
      // 企业公示页码
      pagepublicity: 1,
      // 企业公示的数据
      publicityData: []
    }
  },
  computed: {},
  watch: {},
  created () {
    // 页面加载调用获取公司信息接口
    this.Getinformation()
    // 页面加载调用企业公示的接口
    this.Getpublicity()
    // 页面加载调用名片信息
    this.GetinMingPian()
  },
  mounted () {
    console.log(this.$route.query.msg)
    if (this.$route.query.msg === undefined || this.$route.query.msg === '') {
      this.LoginText = '登录'
    } else {
      this.LoginText = '欢迎您！' + this.$route.query.msg
    }
    const urlDemo = window.location.href
    const label = urlDemo.charAt(urlDemo.length - 1)
    if (label === '0') {
      this.lixinqiyejiaIsShow(1)
    } else if (label === '1') {
      this.lixinqiyejiaIsShow(2)
    } else if (label === '2') {
      this.lixinqiyejiaIsShow(3)
    } else if (label === '3') {
      this.lixinqiyejiaIsShow(5)
      this.guanyuwomen(4)
    } else if (label === '4') {
      this.guanyuwomen(3)
      this.lixinqiyejiaIsShow(5)
    }

    // 自动轮播调用
    // this.initDiv()
  }

}
</script>

<style scoped lang="less">
@import "@/assets/font/font.css";
.wenti827{
  margin-left: 20px;
  display: inline-block;
  width: 200px;
  height: 200px;
}
::v-deep .el-collapse-item__header {
  font-size: 15px;
  background-color: #f4f4f4;
}
/deep/.el-collapse-item__content{
  text-indent: 2em;
  padding-bottom: 0px;
}
/deep/.el-collapse{
  padding-left: 30px;
}
.MoNiBox{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.MoNi{
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999999999999;
  padding: 20px;
  background: white;
  border-radius: 10px;
  transform: translate(-50%,-50%);
}
.head-container{
  // 首页灰色背景公共
  .bgc{
    width: 100%;
    height: 30px;
    background-color:#FFFFFF;
    margin-top: 450px;
  }
  // 首页灰色背景公共定位two
  .two{
    //margin-top:680px;
  }
  // 首页灰色背景公共定位four
  .four{
    width: 100%;
    height:15px;
    background-color:#FFFFFF;
    //margin-top:150px;
  }
  // 中企信立信企业计划灰色背景公共1
  .LIXIN_bgc{
    width: 100%;
    height: 30px;
    background-color:#FFFFFF;
    margin-top:0px;
  }
  // 中企信立信企业计划灰色背景公共2
  .bom1{
    margin-top:220px;
  }
  // 中企信立信企业计划灰色背景公共3
  .bom2{
    margin-top:135px;
  }
  /deep/.el-input__inner{
    height:40px;
    width:400px;
  }
  // 头部盒子区域
  .top_box{
    width: 100%;
    height: 50px;
    background-color:#ededed;
    // 头部内容
    .top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      height: 100%;
      // 头部左侧排版盒子
      .top_left{
        text-align: center;
        width: 314px;
        height: 32px;
        line-height: 32px;
        background: #E60012;
        color: #FFFFFF;
        font-size: 13px;
      }
      // 头部右侧排版盒子
      .top_right{
        width: 650px;
        height: 18px;
        ul{
          display: flex;
          li{
            height: 15px;
            line-height: 15px;
            padding-right: 15px;
            padding-left: 15px;
            font-size:13px;
            cursor: pointer;
            border-right:1.5px solid #666666;
            color: #666666;
            transition: all .5s;
            &:hover{
              color: #930000;
            }
          }
          li:last-child{
            border-right:none;
          }
          .top_right_lastLi{
            width: 150px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  // 头部下轮播图区域
  .carousel_box{
    position: relative;
    width: 100%;
    height: 500px;
    // 背景图片
    .carousel_box_img{
      width: 100%;
      height: 100%;
    }
    // 图片上你内容区域
    .carousel_content_box{
      position: absolute;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
      z-index: 1;
      height: 100%;
      // 头部布局
      .carousel_box_top{
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        border-radius:5px;
        bottom:85px;
        width:504px;
        height: 45px;
        // 全部样式布局
        .carousel_box_top_span{
          display: inline-block;
          width:93px;
          height:50px;
          line-height:50px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          text-align: center;
          background-color:#f8eae8;
          font-size: 18px;
          color: #333333;
        }
        // 输入框样式
        .carousel_box_top_input{
          position: absolute;
          width:290px;
          height:50px;
          outline: none;
          border: none;
          background-color:#f8eae8;
          font-size: 16px;
        }
        // 按钮样式
        .carousel_box_top_btn{
          position: absolute;
          right: 0;
          cursor: pointer;
          text-align: center;
          width: 121px;
          height:50px;
          outline: none;
          border: none;
          background-color:#f8eae8;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          font-size:18px;
          background-color:#fe5241;
          color: #ffffff;
          transition: all .5s;
          i{
            font-size: 20px;
          }
          &:hover{
            background-color:#930000;
          }
        }
      }
      // 头部下面的文字排版
      .carousel_box_top_font{
        margin:10px auto 0;
        width: 200px;
        height: 14px;
        line-height: 14px;
        text-align: center;
        font-size: 8px;
        color: #fb9f98;
      }
      // 右侧排版
      .carousel_box_left{
        position: absolute;
        left:35%;
        transform: translateX(-50%);
        top: 100px;
        width: 547px;
        height: 163px;
        display: flex;
        .left{
          width: 124px;
          height: 100%;
          .left_img{
            width: 105px;
            height: 100px;
          }
        }
        .right{
          .top{
            width:900px;
            height:65px;
            font-size: 90px;
            font-family: FZDBSK;
            font-weight: normal;
            color: #FFFFFF;
          }
          .bottom{
            width:370px;
            height: 65px;
            margin-left:150px;
            margin-top:70px;
            font-family: KaiTi;
            font-size:52px;
            color: #ffffff;
          }
        }
      }
      // 左侧排版
      .carousel_box_right{
        position: absolute;
        right:10px;
        top:60px;
        width: 459px;
        height: 251px;
        p{
          margin-bottom: 24px;
          text-indent: 2rem;
          font-size: 15px;
          color: #ffffff;
        }
        .carousel_box_right_p1{
          text-align: center;
          font-size: 20px;
          color: #ffffff;
        }
      }
      // 底部排版
      .carousel_box_bottom{
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        bottom:130px;
        width:680px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size:30px;
        color: #ffffff;
        .carousel_box_bottom_font{
          font-size: 37px;
          color: #000000;
          font-weight: bold;
        }
      }
    }
  }

  // 导航区域
  .navigation_box{
    width: 100%;
    height: 70px;
    background-color:#bb0200;
    // 内容
    .navigation{

      margin: 0 auto;
      height: 100%;
      // Tab选项卡
      .navigation_ul{
        display: flex;
        justify-content: space-around;
        // Tab栏样式
        .navigation_li{
          display: block;
          width: 200px;
          height: 70px;
          line-height: 70px;
          text-align: center;
          background-color:#bb0200;
          cursor: pointer;
          color: white;
          font-size:18px;
          transition: all .5s;
          // Tab栏悬浮样式
          &:hover{
            background-color:#930000;
          }
        }
        // Tab栏点击后样式
        .active {
          background-color:#930000;
        }
      }
      // 轮播图组件
      .lunBo_box{
        margin-top:35px;
        height: 330px;
        margin-bottom:80px;
      }
      //主体数据
      .subject{
        display: flex;
        margin-bottom: 30px;
        // 企业公示一栏样式
        .subjectOne{
          margin-top:5px;
          // 企业公示以及监督的顶部
          .subject_top{
            width:520px;
            height:40px;
            font-size: 15px;
            color: #000000;
            .subject_top_span{
              font-size: 13px;
            }
          }
          // 企业公示的公司渲染样式以及企业监督的渲染样式
          .company_left,.company_right{
            padding:10px;
            margin-top:10px;
            width:100%;
            //height: 490px;
            background-color:#f6f6f6;
            border-radius: 10px;
          }
          // 企业公示的渲染数据样式
          .company_left{
            .company_left_p1{
              position: relative;
              cursor: pointer;
              //display: flex;
              //justify-content: space-between;
              padding-bottom:5px;
              margin-top:5px;
              margin-bottom: 20px;
              font-size:14.5px;
              border-bottom:2px solid #aeaeae;
              .company_left_span1{
                display: inline-block;
                transition: all .5s;
                &:hover{
                  transform: scale(1.02);
                  color: #930000;
                }
              }
            }
          }
          // 企业监督的渲染数据样式
          .company_right{
            .company_right_box{
              cursor: pointer;
              margin-bottom: 10px;
              padding-bottom:10px;
              line-height:25px;
              border-bottom:1px solid #aeaeae;
              transition: all .5s;
              &:last-child{
                border-bottom: none;
              }
              &:hover{
                transform: scale(1.01);
                .company_right_p1,.company_right_p2,.company_right_p3{
                  color: #930000;
                }
              }
              .company_right_p1{
                font-size: 16px;
                color: #000000;
              }
              .company_right_p2,.company_right_p3{
                font-size: 13px;
                color: #333333;
              }
            }
          }
          // 企业监督和企业公示底部文字样式
          .company_bottom_font{
            width:100%;
            padding:5px;
            background-color:#f2dcda;
            text-align: right;
            margin-right: 20px;
            .company_bottom_font_span{
              cursor: pointer;
              color: #b32418;
              font-size: 15px;
              transition: all .5s;
              &:hover{
                background-color:#930000;
                color:white;
              }
            }
          }
        }
        //企业公示左侧
        .subject_left{
          //flex: 1;
          .subject_supervise_p{
            font-size: 14.5px;
            color: #ff0000;
            margin-left:1050px;
            margin-bottom:-15px;
            cursor: pointer;
            transition: all .5s;
            &:hover{
              color:#930000;
            }
          }
        }
        //企业公示右侧
        .subject_right{
          flex: 1;
          .subject_supervise{
            display: flex;
            justify-content: space-between;
            align-items: end;
            .subject_supervise_p{
              font-size: 14.5px;
              color: #ff0000;
              margin-bottom:5px;
              cursor: pointer;
              transition: all .5s;
              &:hover{
                color:#930000;
              }
            }
          }
        }
      }
    }
  }

  // 中企信立信企业计划模块的布局
  .plan{
    margin-top:80px;
    height: 580px;
  }
  // 中企信立信企业计划的内容盒子
  .plan_box{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    height:500px;
    // 内容布局
    .plan_box1{
      width: 380px;
      height: 100%;
      border: 1px solid #d7d7d7;
      // 头部布局
      .plan_box1_top{
        width: 100%;
        height: 196px;
        border-bottom: 1px solid #d7d7d7;
        img{
          width: 100%;
          height: 100%;
        }
      }
      // 中部布局
      .plan_box1_middle{
        margin:80px auto 0;
        width: 328px;
        height:200px;
        // 字体样式更改
        .plan_box1_middle_p1{
          text-align: center;
          padding-bottom:20px;
          border-bottom: 1px dashed #d7d7d7;
          font-size: 23px;
          font-weight: bold;
          color: #333333;
        }
        .plan_box1_middle_p2{
          margin-top: 30px;
          text-indent:2rem;
          font-size:18px;
          font-weight: bold;
          color: #333333;
          line-height:40px;
        }

      }
      // 按钮布局{
      .plan_box1_btn{
        margin:100px auto 0;
        width: 135px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background-color:#be1300;
        font-size:19px;
        border: none;
        cursor: pointer;
        color: white;
        border-radius: 22px;
        transition: all .5s;
        &:hover{
          transform: translateY(-5px);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          background-color: #d5281e;
        }
      }
    }
  }

  // 底部组件样式
  .bottom{
    margin-top:620px;
  }

  // 第二板块 中企信立信企业计划
  .Lixin{
    margin-top:40px;
    min-height:750px;
    // 中企信立信企业计划的盒子板块
    .Lixin_box{
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      min-height:750px;
      // 中企信立信企业计划的盒子里左侧排版布局
      .Lixin_left{
        width: 240px;
        min-height:100%;
        background-color:#f4f4f4;
        border-radius: 10px;
        padding: 10px;
        // 左侧导航栏大字
        .Lixin_btn1{
          width: 100%;
          min-height: 50px;
          line-height: 50px;
          text-align: center;
          border-bottom: solid 1px #e2e2e2;
          font-size: 18px;
          color: #515151;
          &:nth-child(1):hover{
            cursor: pointer;
            color: #930000;
          }
        }
        // 默认添加样式
        .default{
          color: #930000;
        }
        // 左侧导航栏小字
        .Lixin_btn2{
          width: 100%;
          min-height: 35px;
          line-height: 35px;
          text-align: center;
          border-bottom: solid 1px #e2e2e2;
          font-size: 15px;
          color: #515151;
          // 这个span就是导航栏的每一个点击对象
          .span{
            cursor: pointer;
            &:hover{
              color: #930000;
            }
          }
          // 这个就是下拉的点击对象
          .span_select{
            cursor: pointer;
            .span_select_span1{
              &:hover{
                color: #930000;
              }
            }
            // 这个是已经下拉之后的显示对象
            .span_select_span1_son{
              margin-left:90px;
              &:hover{
                color: #930000;
              }
            }
          }
        }
      }
      // 中企信立信企业计划的盒子里右侧排版布局
      .Lixin_right{
        width:920px;
        height:750px;
        overflow-y: auto;
        background-color:#f4f4f4;
        border-radius: 10px;
        // 关于中企信立信企业计划的盒子内容
        .lixinqiyejihua{
          .Lixin_right_box1{
            margin: 45px auto 0;
            width: 750px;
            height: 256px;
            .Lixin_right_box1_p1{
              font-size: 25px;
              font-weight: normal;
              color: #515151;
            }
            .Lixin_right_box1_p2{
              font-size: 18px;
              font-weight: normal;
              //line-height: 50px;
              line-height: 30px;
              color: #515151;
              //text-indent: 2em;
              .Lixin_right_box1_span1{
                display:inline-block;
                text-indent: 2rem;
              }
              .Lixin_right_box1_span2{
                font-size: 15px;
              }
              .zhanshi2LLb1{
                margin-left: 100px;
                width: 225px;
                height: 420px;
              }
              .zhanshi2LLb2{
                margin-left: 100px;
                margin-bottom: 45px;
                width: 245px;
                height: 350px;
              }
              .zhanshi2LLb3{
                margin-left: 130px;
                width: 480px;
                //height: 400px;
              }
              .Lixin_right_box1_img{
                width: 360px;
                height: 250px;
              }
              .Lixin_right_box1_img2{
                width: 360px;
                height: 250px;
              }
              .Lixin_right_box1_img3{
                width: 360px;
                height: 250px;
              }
              .Lixin_right_box1_img4{
                width: 360px;
                height: 250px;
              }
            }
          }
          .Lixin_right_box2{
            margin: 138px auto 0;
            width: 721px;
            height: 256px;
            .Lixin_right_box2_p1{
              font-size: 25px;
              font-weight: normal;
              color: #515151;
            }
            .Lixin_right_box2_p2{
              font-size: 18px;
              font-weight: normal;
              line-height: 30px;
              color: #515151;
              text-indent: 2em;
            }
          }
        }
        // 服务规范的盒子的内容排版布局
        .fuwuguifan{
          padding: 30px 50px;
          .fuwuguifan_p1{
            font-size: 20px;
            color: #515151;
          }
          .fuwuguifan_box1{
            font-size: 15px;
            font-weight: normal;
            line-height: 34px;
            color: #515151;
          }
        }
        // 加入条件的盒子内容排版布局
        .jiarutiaojian{
          margin: 45px auto 0;
          width: 721px;
          height: 310px;
          .jiarutiaojian_box{
            p{
              font-size: 20px;
              line-height: 60px;
              color: #515151;
            }
          }
        }
        // 荣誉牌匾的盒子内容排版布局
        .rongyupaibian{
          margin: 45px auto 0;
          width: 710px;
          height:700px;
          .rongyupaibian_h2{
            font-size: 20px;
            font-weight: normal;
            color: #515151;
          }
        }
        // 官方网站盒子的内容排版布局
        .guanfangwangzhan{
          margin: 45px auto 0;
          width: 710px;
          height:700px;
          .guanfangwangzhan_h2{
            font-size: 20px;
            font-weight: normal;
            color: #515151;
          }
        }
        // 更多的内容排版布局
        .gengduo{
          margin: 45px auto 0;
          width: 710px;
          height:700px;
          .gengduo_h2{
            font-size: 20px;
            font-weight: normal;
            color: #515151;
          }
        }
      }

    }
    // 相关链接的排版布局
    .Lixin_RelatedLinks{
      margin-top:-40px;
    }
  }

  // 第三板块 企业公示
  .GongShi{
    position: relative;
    margin-top:40px;
    min-height:750px;
    // 公示盒子里面的内容
    .GongShibox{
      margin-top: 15px;
      min-height:750px;
      background-color:#f4f4f4;
      border-radius: 10px;
      padding: 10px;
      // 企业公示头部内容
      .GongShitop{
        width: 100%;
        height: 50px;
        border-bottom: solid 1px #e2e2e2;
        padding-left: 100px;
        // 企业公示导航栏区域
        .GongShi_span{
          display: inline-block;
          margin-right:18px;
          width:150px;
          height: 100%;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
          font-size: 20px;
          font-weight: bold;
          color: #515151;
          transition: all .5s;
          &:hover{
            color: #930000;
            transform: translateX(2px);
          }
        }
        // 默认样式
        .GongshiDefault{
          color: #930000;
        }
      }
      // GongShi_box1
      .GongShi_box1{
        // 公司的主体内容1
        .GongShi_content{
          margin-top: 20px;
          width:50%;
          height:70px;
          float: left;
          border-bottom: solid 1px #e2e2e2;
          //display: flex;
          //justify-content: space-between;
          // 左侧布局
          .left{
            //width: 48%;
            //height: 100%;
            line-height:33px;
            cursor: pointer;
            margin-left: 35px;
            transition: all .5s;
            &:hover{
              transform: translateX(5px);
            }
            .left_p1{
              font-size: 17px;
              letter-spacing: 1px;
              color: #515151;
            }
            .left_span1{
              font-size: 12px;
              letter-spacing: 1px;
              color: #ac2317;
              margin-right:12px;
            }
            .left_span2{
              font-size: 14px;
              letter-spacing: 1px;
              color: #515151;
            }

          }
          // 右侧布局
          .right{
            width: 48%;
            height: 100%;
            line-height:33px;
            cursor: pointer;
            transition: all .5s;
            &:hover{
              transform: translateX(5px);
            }
            .right_p1{
              font-size: 17px;
              letter-spacing: 1px;
              color: #515151;
            }
            .right_span1{
              font-size: 12px;
              letter-spacing: 1px;
              color: #ac2317;
              margin-right:12px;
            }
            .right_span2{
              font-size: 14px;
              letter-spacing: 1px;
              color: #515151;
            }

          }
        }
      }

      // 公司的主体内容2
      .GongShi_content2{
        margin-top: 20px;
        width: 100%;
        height:100px;
        border-bottom: solid 1px #e2e2e2;
        display: flex;
        justify-content: space-between;
        // 左侧布局
        .left{
          width: 48%;
          height: 100%;
          line-height:33px;
          cursor: pointer;
          margin-left: 35px;
          transition: all .5s;
          &:hover{
            transform: translateX(5px);
          }
          .left_p1{
            font-size: 17px;
            letter-spacing: 1px;
            color: #515151;
          }
          .left_span1{
            font-size: 14px;
            letter-spacing: 1px;
            color: #ac2317;
            margin-right: 32px;
          }
          .left_span2{
            font-size: 14px;
            letter-spacing: 1px;
            color: #515151;
          }
          .left_p3{
            letter-spacing: 1px;
            color: #ac2317;
          }
        }
        // 右侧布局
        .right{
          width: 48%;
          height: 100%;
          line-height:33px;
          cursor: pointer;
          transition: all .5s;
          &:hover{
            transform: translateX(5px);
          }
          .right_p1{
            font-size: 17px;
            letter-spacing: 1px;
            color: #515151;
          }
          .right_span1{
            font-size: 14px;
            letter-spacing: 1px;
            color: #ac2317;
            margin-right: 32px;
          }
          .right_span2{
            font-size: 14px;
            letter-spacing: 1px;
            color: #515151;
          }
          .right_p3{
            letter-spacing: 1px;
            color: #ac2317;
          }
        }
      }
      // 下一页的内容
      .paging{
        position: absolute;
        right: 50px;
        bottom: 200px;
        width:80px;
        height:30px;
        background-color: #930000;
        line-height: 30px;
        text-align: center;
        font-size: 13px;
        color: white;
        cursor: pointer;
        transition: all .5s;
        &:hover{
          background-color: red;
          transform: scale(1.05);
        }
      }
    }
    .Lixin_RelatedLinks{
      margin-top:-32px;
    }
  }

  // 第四板块 申请加入
  .ShenQing{
   padding: 30px;
    .ShenQing_p1{
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      color: #515151;
      margin-bottom: 40px;
    }
    .ShenQing_p2{
      font-size: 18px;
      line-height: 25px;
      color: #515151;
      a{
        text-decoration: underline;
        color: #ac2317;
      }
    }
    .ShenQing_img{
      width: 250px;
      height: 230px;
      //margin: 25px auto 0px;
      margin: 25px 0px 0px 223px;
      .ShenQing_img_img{
        width: 100%;
        height: 100%;
      }
    }
  }

  // 第五板块 进度查询
  .YouJI{
    padding: 30px;
    .YouJI_p1{
      height:50px;
      text-indent: 2rem;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin-bottom: 20px;
      color: #515151;
      border-bottom: solid 1px #e2e2e2;
    }
    .YouJI_p2{
      margin: 0 auto;
      cursor: pointer;
      width: 303px;
      height: 37px;
      line-height: 37px;
      text-align: center;
      background-color: #f54634;
      border-radius: 5px;
      font-size: 22px;
      color: #ffffff;
      transition: all .5s;
      &:hover{
        background-color: #e71f0b;
      }
    }
  }

  // 第六板块 关于我们
  .guanyu_p2{
    width: 614px;
    height: 147px;
    font-size: 18px;
    font-weight: normal;
    line-height: 42px;
    color: #515151;
    margin-left: 100px;
  }
  .guanyu_btn1{
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    text-align: center;
    border-bottom: solid 1px #e2e2e2;
    font-size: 18px;
    color: #515151;
    &:hover{
      cursor: pointer;
      color: #930000;
    }
  }
  .guanyuDefault{
    color: #930000;
  }
  // 关于我们内容的第二模块之联系我们
  .neirong2_guanyu_p2{
    width: 714px;
    height: 147px;
    font-size: 18px;
    font-weight: normal;
    line-height: 32px;
    color: #515151;
    margin-left: 100px;
  }
  // 图片样式更改
  .neirong2_guanyu_img{
    width:523px;
    height:409px;
    margin: 0 auto 40px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
