import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomePage from '@/views/HomePage/HomePage'
import Move from '@/views/Move/Move'
import HuaDong from '@/views/HuaDong'
import Head from '@/components/Head/Head'
import Login from '@/components/Login/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'head',
    component: Head
  },
  {
    path: '/move',
    name: 'move',
    component: Move
  },
  {
    path: '/HuaDong',
    name: 'HuaDong',
    component: HuaDong
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
